export const translations = {
  de: {
    pricing: {
      label: 'Tarife',
      url: 'https://www.pageflow.io/de/tarife/'
    },
    blog: {
      label: 'Blog',
      url: 'https://www.pageflow.io/de/blog/'
    },
    start: {
      label: 'Start',
      url: 'https://www.pageflow.io/de/'
    },
    register: {
      label: 'Kostenlos testen',
      url: 'https://creator.hosted-pageflow.com/registrations/new?locale=de'
    },
    login: {
      label: 'Login',
      url: 'https://creator.hosted-pageflow.com/admin/login?locale=de'
    },
    legal: {
      label: 'Impressum',
      url: 'https://www.pageflow.io/de/impressum/'
    },
    terms: {
      label: 'AGB',
      url: 'https://www.pageflow.io/de/agb/'
    },
    privacy: {
      label: 'Datenschutz',
      url: 'https://www.pageflow.io/de/datenschutzerklaerung/'
    },
    helpdesk: {
      label: 'Helpdesk',
      url: 'https://pageflow.freshdesk.com/de/support/home/'
    },
    changelog: {
      label: 'Changelog',
      url: 'https://www.pageflow.io/de/changelog/'
    },

    product: 'Produkt',
    resources: 'Ressourcen',
    about: 'Über uns',
    followUs: 'Folge uns',
    toggleMenu: 'Menü öffnen/schließen'
  },
  en: {
    pricing: {
      label: 'Pricing',
      url: 'https://www.pageflow.io/en/pricing/'
    },
    blog: {
      label: 'Blog',
      url: 'https://www.pageflow.io/en/blog/'
    },
    start: {
      label: 'Start',
      url: 'https://www.pageflow.io/en/'
    },
    register: {
      label: 'Try for free',
      url: 'https://creator.hosted-pageflow.com/registrations/new?locale=en'
    },
    login: {
      label: 'Log in',
      url: 'https://creator.hosted-pageflow.com/admin/login?locale=en'
    },
    legal: {
      label: 'Legal',
      url: 'https://www.pageflow.io/en/legal/'
    },
    terms: {
      label: 'Terms and Conditions',
      url: 'https://www.pageflow.io/en/terms/'
    },
    privacy: {
      label: 'Privacy Policy',
      url: 'https://www.pageflow.io/en/privacy-policy/'
    },
    helpdesk: {
      label: 'Helpdesk',
      url: 'https://pageflow.freshdesk.com/en/support/home'
    },
    changelog: {
      label: 'Changelog',
      url: 'https://www.pageflow.io/en/changelog/'
    },

    product: 'Product',
    resources: 'Resources',
    about: 'About',
    followUs: 'Follow us',
    toggleMenu: 'Toggle Menu'
  }
};
